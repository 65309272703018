html, body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #1d54c2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; /* Changed to white for contrast against the background */
}

.App-header {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width */
  color: #1d54c2;
}

.App-link {
  color: #61dafb;
}

.App-description {
  text-align: justify;
  max-width: 800px;
  margin: 20px;
  line-height: 1.6;
  font-size: 1.1em;
  padding: 0 10px;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .App-description {
    text-align: left; /* Left align text for better readability on small screens */
    margin: 15px;
    font-size: 1em;
    padding: 0 5%;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .App-description {
    text-align: left; /* Left align text for better readability on very small screens */
    font-size: 0.9em;
    padding: 0 10px;
  }
}

/* Adjustments for large screens (e.g., 4K) */
@media (min-width: 1920px) {
  .App-description {
    font-size: 1.4em;
    max-width: 1200px;
    padding: 0 20px;
  }

  .App-header {
    padding: 30px;
  }
}
